const firebaseConfig = {
  apiKey: "AIzaSyDBFZ2W0mC81H1ApRmYZP0KsITKu_UMirU",
  authDomain: "bizjedisaas.firebaseapp.com",
  databaseURL: "https://bizjedisaas-default-rtdb.firebaseio.com",
  projectId: "bizjedisaas",
  storageBucket: "bizjedisaas.appspot.com",
  messagingSenderId: "64988607665",
  appId: "1:64988607665:web:96f937cd2b8767bbb01e90",
  measurementId: "G-NNCPJSS8MZ",
};

export default firebaseConfig;

export default function LogoWithoutText() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="43.447"
      height="33.554"
      fill="none"
      version="1.1"
      viewBox="0 0 53.447 43.554"
      xmlSpace="preserve"
    >
      <defs>
        <linearGradient id="linearGradient4950">
          <stop offset="0" stop-color="#393ab7" stop-opacity="1"></stop>
          <stop offset="1" stop-color="#09d8f3" stop-opacity="0.984"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient4952"
          x1="60.698"
          x2="114.145"
          y1="-60.664"
          y2="-60.664"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient4950"
        ></linearGradient>
        <linearGradient
          id="linearGradient8542"
          x1="60.698"
          x2="114.145"
          y1="-60.664"
          y2="-60.664"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient4950"
        ></linearGradient>
        <linearGradient
          id="linearGradient8544"
          x1="60.698"
          x2="114.145"
          y1="-60.664"
          y2="-60.664"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient4950"
        ></linearGradient>
        <linearGradient
          id="linearGradient8546"
          x1="60.698"
          x2="114.145"
          y1="-60.664"
          y2="-60.664"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient4950"
        ></linearGradient>
        <linearGradient
          id="linearGradient8548"
          x1="60.698"
          x2="114.145"
          y1="-60.664"
          y2="-60.664"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient4950"
        ></linearGradient>
      </defs>
      <g
        fill="url(#linearGradient4952)"
        fill-opacity="1"
        transform="translate(-60.698 82.44)"
      >
        <g fill="url(#linearGradient8548)" fill-opacity="1">
          <path
            fill="url(#linearGradient8542)"
            stroke-width="0.278"
            d="M79.812-50.426h-.383c-1.542 0-2.791-1.728-2.791-3.86 0-2.132 1.25-3.86 2.79-3.86h.384c1.541 0 2.791 1.728 2.791 3.86 0 2.132-1.25 3.86-2.791 3.86z"
          ></path>
          <path
            fill="url(#linearGradient8544)"
            stroke-width="0.278"
            d="M96.186-50.426h-.383c-1.541 0-2.791-1.728-2.791-3.86 0-2.132 1.25-3.86 2.79-3.86h.384c1.542 0 2.791 1.728 2.791 3.86 0 2.132-1.25 3.86-2.79 3.86z"
          ></path>
          <path
            fill="url(#linearGradient8546)"
            stroke-width="0.236"
            d="M112.01-38.887c-1.075 0-1.985-1.358-2.099-3.114-1.201-18.788-9.947-23.195-21.644-23.195-11.683 0-22.144 4.407-23.345 23.195-.114 1.777-1.012 3.114-2.1 3.114-1.251 0-2.25-1.797-2.111-3.866 1.454-22.3 12.847-39.688 26.718-39.688s25.277 17.389 26.705 39.688c.127 2.07-.86 3.866-2.124 3.866z"
          ></path>
        </g>
      </g>
    </svg>
  );
}
